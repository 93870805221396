import React from "react";

function Footer() {
  return (
    <footer className="App-footer">
      <p>Kontakt: alexundo2020@gmail.com</p>
    </footer>
  );
}

export default Footer;
