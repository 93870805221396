import React from "react";

function MainSection() {
  return (
    <main className="App-content">
      <p className="punane">
        Kui otsid veebiarendajat, siis võta julgelt ühendust. Teostan allolevat
        veebiarendust.
      </p>

      <div className="textDiv3">
        <h2>
          Veebirakendused koos andmebaasiga. React, Node, Express, Mysql. CRM,
          lao tarkvara, rendi rakendused, toidu tellimise rakendused, foorumid,
          suhtlusrakendused, tutvumisportaalid jne.
        </h2>

        <div className="border">
          <h2 className="naidis">CRM</h2>
          <p>
            Ettevõtte crm vastavalt teie soovile. Teie kliendibaasiga ja teie
            soovitud funktsioonidega. Soovi korral saan näidata CRM-i, millel
            topelt audentimine, JWT igal kasutajal. Erinevad vaated: kalendrid
            kontaktide märkimiseks, kliendikaardid, pakkumised klientidele,
            teadete saatmised, failide üles laadimised, statistika kontaktidest,
            pakkumistest jne.
          </p>
        </div>

        <div className="border">
          <h2 className="naidis">Kohviku veebirakendus</h2>
          <p>
            Igal kasutajal on oma konto. Audentimine teostatud JWT. Kasutajad
            saavad tellida endale nädala söögi ette ja olemasolevaid toite kohe
            läbi äpi osta. Vaadata mida sõi, millal sõid, kui palju maksis, kui
            palju kogu toit kokku maksis, loobuda ettetellitud toidust jne.
            Kohvik saab teha menüüd, kustutada menüü, lisada sööjaid, kustutada
            sööjaid, muuta toite, lisada toite, teha kuu kokkuvõtet, vaadata
            tellitud toitude koguseid, kes ei tellinud jne.
          </p>
        </div>
        <div className="border">
          <h2 className="naidis">Lao lisatööd ja sissetulevad kaubad</h2>
          <p>
            Kõikidel kasutajatel oma konto. Audentimine teostatud JWT.
            Laotöötajad saavad sisestada erinevaid töid ja sissetulevaid kaupu.
            Logistiku ja laojuhataja vaates näeb töötajate tegevusi ja
            sissetulevaid kaupu. Laojuhataja ja logistik näevad, millega
            laotöötajad tegelevad jooksvalt. Raportid, kokkuvõtted, kasutajate
            lisamine, kustutamine jne.
          </p>
        </div>
      </div>
    </main>
  );
}

export default MainSection;
